import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {BaseComponent} from './views/layout/base/base.component';
import {AuthGuard} from './core/guard/auth.guard';
import {ErrorPageComponent} from './views/pages/error-page/error-page.component';


const routes: Routes = [
  {path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule)},
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'apps',
        loadChildren: () => import('./views/pages/apps/apps.module').then(m => m.AppsModule)
      },
      {
        path: 'ui-components',
        loadChildren: () => import('./views/pages/ui-components/ui-components.module').then(m => m.UiComponentsModule)
      },
      {
        path: 'advanced-ui',
        loadChildren: () => import('./views/pages/advanced-ui/advanced-ui.module').then(m => m.AdvancedUiModule)
      },
      {
        path: 'form-elements',
        loadChildren: () => import('./views/pages/form-elements/form-elements.module').then(m => m.FormElementsModule)
      },
      {
        path: 'advanced-form-elements',
        loadChildren: () => import('./views/pages/advanced-form-elements/advanced-form-elements.module').then(m => m.AdvancedFormElementsModule)
      },
      {
        path: 'charts-graphs',
        loadChildren: () => import('./views/pages/charts-graphs/charts-graphs.module').then(m => m.ChartsGraphsModule)
      },
      {
        path: 'tables',
        loadChildren: () => import('./views/pages/tables/tables.module').then(m => m.TablesModule)
      },
      {
        path: 'icons',
        loadChildren: () => import('./views/pages/icons/icons.module').then(m => m.IconsModule)
      },
      {
        path: 'general',
        loadChildren: () => import('./views/pages/general/general.module').then(m => m.GeneralModule)
      },
      {
        path: 'orders',
        loadChildren: () => import('./views/pages/orders/orders.module').then(m => m.OrdersModule)
      },
      {
        path: 'news',
        loadChildren: () => import('./views/pages/news/news.module').then(m => m.NewsModule)
      },
      {
        path: 'categories',
        loadChildren: () => import('./views/pages/categories/categories.module').then(m => m.CategoriesModule)
      },
      {
        path: 'subcategories',
        loadChildren: () => import('./views/pages/subcategories/subcategories.module').then(m => m.SubcategoriesModule)
      },
      {
        path: 'products',
        loadChildren: () => import('./views/pages/products/products.module').then(m => m.ProductsModule)
      },
      {
        path: 'menu',
        loadChildren: () => import('./views/pages/menu/menu.module').then(m => m.MenuModule)
      },
      {
        path: 'kitchen',
        loadChildren: () => import('./views/pages/kitchen/kitchen.module').then(m => m.KitchenModule)
      },
      {
        path: 'settings',
        loadChildren: () => import('./views/pages/settings/settings.module').then(m => m.SettingsModule)
      },
      {
        path: 'roles',
        loadChildren: () => import('./views/pages/roles/roles.module').then(m => m.RolesModule)
      },
      {
        path: 'users',
        loadChildren: () => import('./views/pages/users/users.module').then(m => m.UsersModule)
      },
      {
        path: 'business',
        loadChildren: () => import('./views/pages/business/business.module').then(m => m.BusinessModule)
      },
      {
        path: 'brands',
        loadChildren: () => import('./views/pages/brands/brands.module').then(m => m.BrandsModule)
      },
      {
        path: 'campaigns',
        loadChildren: () => import('./views/pages/campaigns/campaigns.module').then(m => m.CampaignsModule)
      },
      {
        path: 'promotions',
        loadChildren: () => import('./views/pages/promotions/promotions.module').then(m => m.PromotionsModule)
      },
      {
        path: 'reports',
        loadChildren: () => import('./views/pages/reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: 'classifications',
        loadChildren: () => import('./views/pages/classifications/classifications.module').then(m => m.ClassificationsModule)
      },
      {
        path: 'table-reservations',
        loadChildren: () => import('./views/pages/table-reservations/table-reservations.module').then(m => m.TableReservationsModule)
      },
      {
        path: '', redirectTo: 'dashboard', pathMatch: 'full'
      },
      // { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
    ]
  },
  {
    path: 'error',
    component: ErrorPageComponent,
    data: {
      'type': 404,
      'title': 'Page Not Found',
      'desc': 'Oopps!! The page you were looking for doesn\'t exist.'
    }
  },
  {
    path: 'error/:type',
    component: ErrorPageComponent
  },
  {path: '**', redirectTo: 'error', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
