import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppNotifications } from "../shared/mail";
import { orderPost } from "../shared/interfaces/order";
import { User } from "../shared/user";

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  getDatos: any;
  // credenciales
  urlBase = environment.urlBase;
  apikey = environment.apikey;

  constructor(
    private http: HttpClient
  ) {
  }

  sendGET_SettingsApp() {
    const headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + '/SettingsApp/RegisterScreen/' + this.apikey + '/1/1', { headers });
  }

  sendGET_Splashscreen() {
    const headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + '/SettingsApp/SplashScreen/' + this.apikey + '/1/1', { headers });
  }

  sendGET_OneSettingsApp(id: string) {
    const headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + '/SettingsApp/' + id + '/' + this.apikey + '/1/1', { headers });
  }

  sendGET_Menu() {
    const headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + '/AdminMenu/q2d1GFsX93H1NMAT4MXo/' + this.apikey + '/1/1', { headers });
  }

  sendGET_BusinessPlace() {
    const headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + '/BusinessPlace/' + this.apikey + '/1/1', { headers });
  }

  sendGET_BusinessPlace_One(id: string) {
    const headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + '/BusinessPlace/' + id + '/' + this.apikey + '/1/1', { headers });
  }

  async sendPUT_Business(messages: any, id: string) {
    return new Promise((resolve, reject) => {
      let raw = JSON.stringify(messages);
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      console.log("http: ", raw);

      this.getDatos = this.http.put<any>(this.urlBase + '/BusinessPlace/' + id + '/' + this.apikey + '/1/1', raw, { headers })
        .subscribe(data => {
          console.log(data);
          resolve(data);
        }, error => {
          console.log(error);
          reject(error);
        });
    })
  }

  sendPOST_Business(messages: any) {
    return new Promise((resolve, reject) => {
      let raw = JSON.stringify(messages);
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      console.log("http: ", raw);

      return this.getDatos = this.http.post<any>(this.urlBase + '/BusinessPlace/' + this.apikey + '/1/1', raw, { headers })
        .subscribe(data => {
          console.log(data);
          resolve(data)
        }, error => {
          console.log(error);
        });
    })
  }

  sendGET_Orders() {
    const headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + '/Orders/' + this.apikey + '/1/1', { headers });
  }

  sendGET_OrdersOne(id: string) {
    const headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + '/Orders/' + id + '/' + this.apikey + '/1/1', { headers });
  }

  getOrdersByStatus(status: string) {
    const headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + '/Orders/' + this.apikey + '/' + status + '/1/1/1/1/1', { headers });
  }

  sendGET_Home_List_Product() {
    const headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + '/Products/' + this.apikey + '/0/1', { headers });
  }

  sendGET_Home_List_Product_All() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'qs-limit': "6000",
    });
    return this.getDatos = this.http.get<any>(this.urlBase + '/Products/' + this.apikey, { headers });
  }

  sendGET_Home_List_Product_By_Page(data: { total_docs_by_collection: number; num_page: number; limit: number; offset: number; order_by: string; order: string; }) {
    const headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + '/Products/' + this.apikey + '/1/' + data.total_docs_by_collection + '/' + data.num_page + '/' + data.limit + '/' + data.offset + '/' + data.order_by + '/' + data.order, { headers });
  }

  sendGET_One_Product(id: string) {
    const headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + '/Products/' + id + '/' + this.apikey + '/0/1', { headers }); // Para Otros
    // return this.getDatos = this.http.get<any>(this.urlBase + '/Products/' + id + '/' + this.apikey + '/1/1', { headers }); // Para New Rolling
  }

  sendGET_Category() {
    const headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + '/CategoriesApp/1/0', { headers });
  }

  sendGET_One_Category(id: string) {
    const headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + '/CategoriesApp/' + id + '/1/0', { headers });
  }

  getOneUser(uid: string): Observable<any> {
    const headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + '/Users/' + uid + '/' + this.apikey + '/1/1', { headers });
  }

  getAllUser(): Observable<any> {
    const headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + '/Users/' + this.apikey + '/0/1', { headers });
  }

  sendGET_ProductoTop(): Observable<any> {
    const headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + '/ProductsTop/' + this.apikey + '/1/1', { headers });
  }

  sendGET_HelpHeads(): Observable<any> {
    const headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + '/HelpContent/heads/' + this.apikey, { headers });
  }

  sendGET_HelpContents(id: string): Observable<any> {
    const headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + '/HelpContent/' + id + '/heads/contents/' + this.apikey, { headers });
  }

  async sendPOST_Category(messages: any) {
    let raw = JSON.stringify(messages);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    // console.log("http: ", raw);
    return this.getDatos = this.http.post<any>(this.urlBase + '/Categories/' + this.apikey + '/0/1', raw, { headers }).subscribe(data => {
      console.log(data['_body']);
    }, error => {
      console.log(error);
    });
  }

  async sendPUT_Category(messages: any, id: string, type = false) {
    let endUrl = type ? '/666' : `${'/' + this.apikey}/0/1`;
    return new Promise((resolve, reject) => {
      let raw = JSON.stringify(messages);
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'api-key': this.apikey,
      });
      console.log("http: ", raw);

      this.getDatos = this.http.put<any>(this.urlBase + '/Categories/' + id + endUrl, raw, { headers })
        .subscribe(data => {
          console.log(data);
          resolve(data);
        }, error => {
          console.log(error);
          reject(error);
        });
    })
  }

  sendPUT_Orders(messages: any, id: string, total_update: any) {
    var raw = JSON.stringify({
      items: messages,
      badgeColor: "warning",
      orders_status_uid: "Modified",
      total: Number(total_update)
    });
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    console.log("http: ", raw);

    return this.getDatos = this.http.put<any>(this.urlBase + '/Orders/' + id + '/' + this.apikey + '/1/1', raw, { headers })
      .subscribe(data => {
        console.log(data['_body']);
      }, error => {
        console.log(error);
      });
  }

  sendPUT_Orders_Accept(messages: any, id: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.put<any>(`${this.urlBase}/Orders/${id}/${this.apikey}/1/1`, messages, { headers }).toPromise();
  }

  sendPUT_Product(messages: any, id: string) {
    return new Promise((resolve, reject) => {
      var raw = JSON.stringify(messages);
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      console.log("http: ", raw);

      this.getDatos = this.http.put<any>(this.urlBase + '/Products/' + id + '/' + this.apikey + '/0/1', raw, { headers })
        .subscribe(data => {
          console.log(data);
          resolve(data);
        }, error => {
          console.log(error);
          reject(error);
        });
    })
  }

  async sendDELETE_Product(id: string) {
    var raw = JSON.stringify({ product_active: false });
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    // console.log("http: ", raw);

    return this.getDatos = this.http.put<any>(this.urlBase + '/Products/' + id + '/' + this.apikey + '/0/1', raw, { headers })
      .subscribe(data => {
        console.log(data['_body']);
      }, error => {
        console.log(error);
      });
  }

  sendPOST_Product(messages: any) {
    return new Promise((resolve, reject) => {
      let raw = JSON.stringify(messages);
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      console.log("http: ", raw);

      return this.getDatos = this.http.post<any>(this.urlBase + '/Products/' + this.apikey + '/0/1', raw, { headers })
        .subscribe(data => {
          console.log(data);
          resolve(data)
        }, error => {
          console.log(error);
        });
    })
  }

  sendGET_Settings() {
    const headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + '/SettingsApp/' + this.apikey + '/1/1', { headers });
  }

  getStatusOrders() {
    const headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + '/OrderStatus/' + this.apikey + '/1/1', { headers });
  }

  async sendEmail(messages) {
    return new Promise((resolve, reject) => {
      let raw = JSON.stringify(messages);
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      this.http.post<AppNotifications>(this.urlBase + '/AppNotifications/' + this.apikey + '', raw, { headers }).subscribe(data => {
        console.log(data);
        resolve(data);
      }, error => {
        reject(error)
        console.log(error);
      });
    })
  }

  getOneProduct(id) {
    const headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + '/Products/' + id + '/' + this.apikey + '/0/1', { headers });
  }

  getProductsByCategory(id, total_docs, num_page, limit, num_docs_page) {
    let headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + `/Products/${this.apikey}/1/${id}/${total_docs}/${num_page}/${limit}/${num_docs_page}/product_name/asc`, { headers });
  }

  async updateProduct(message_post, id_product) {
    return new Promise((resolve, reject) => {
      let raw = JSON.stringify(message_post);
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'api-key': this.apikey,
      });
      return this.getDatos = this.http.put<any>(this.urlBase + `/Products/` + id_product, raw, { headers }).subscribe(data => {
        //console.log(data['_body']);
        resolve(data['_body']);
      }, error => {
        reject(error);
        //console.log(error);
      });
    })
  }

  getSubcategories(orderField: string, orderBy: string) {
    const headers = new HttpHeaders({
      'api-key': this.apikey,
    });
    return this.getDatos = this.http.get<any>(this.urlBase + `/SubCategories/${orderField}/${orderBy}`, { headers });
  }

  updateSubcategory(message_post, id_subcategory: any, b = false) {
    let endUrl = b ? `/SubCategories/${id_subcategory}/666` : `/SubCategories/${id_subcategory}`;
    return new Promise((resolve, reject) => {
      let raw = JSON.stringify(message_post);
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'api-key': this.apikey,
      });
      return this.getDatos = this.http.put<any>(this.urlBase + endUrl, raw, { headers }).subscribe(data => {
        resolve(data['_body']);
      }, error => {
        reject(error);
      });
    })
  }

  getSubcategory(id) {
    let headers = new HttpHeaders({
      'api-key': this.apikey,
    });
    return this.getDatos = this.http.get<any>(this.urlBase + `/SubCategories/${id}`, { headers });
  }

  async saveSubcategory(message_post) {
    let raw = JSON.stringify(message_post);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'api-key': this.apikey,
    });
    return this.getDatos = this.http.post<any>(this.urlBase + '/SubCategories', raw, { headers }).subscribe(data => {
    }, error => {
    });
  }

  sendGET_brand(orderField, orderBy) {
    const headers = new HttpHeaders({
      'api-key': this.apikey,
    });
    return this.getDatos = this.http.get<any>(this.urlBase + `/Brands/${orderField}/${orderBy}`, { headers });
  }

  sendGET_supplier(orderField, orderBy) {
    const headers = new HttpHeaders({
      'api-key': this.apikey,
    });
    return this.getDatos = this.http.get<any>(this.urlBase + `/Suppliers/${orderField}/${orderBy}`, { headers });
  }

  async sendPUT_Settings(messages, id) {
    return new Promise((resolve, reject) => {
      let raw = JSON.stringify(messages);
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });

      this.getDatos = this.http.put<any>(this.urlBase + '/SettingsApp/' + id + '/' + this.apikey + '/0/1', raw, { headers })
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
        });
    })
  }

  async saveOrder(data) {
    return new Promise((resolve, reject) => {
      let raw = JSON.stringify(data);
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      this.http.post<orderPost>(this.urlBase + '/Orders/' + this.apikey + '/1/0', raw, { headers })
        .subscribe(data => {
          resolve(data)
        }, error => {
          reject(error);
        });
    });
  }

  async updateBrand(message_post, id) {
    return new Promise((resolve, reject) => {
      let raw = JSON.stringify(message_post);
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'api-key': this.apikey,
      });
      return this.getDatos = this.http.put<any>(this.urlBase + `/Brands/${id}`, raw, { headers }).subscribe(data => {
        // console.log(data['_body']);
        resolve(data['_body']);
      }, error => {
        reject(error);
        // console.log(error);
      });
    })
  }

  getBrand(id) {
    let headers = new HttpHeaders({
      'api-key': this.apikey,
    });
    return this.getDatos = this.http.get<any>(this.urlBase + `/Brands/${id}`, { headers });
  }

  async saveBrand(messages) {
    let raw = JSON.stringify(messages);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'api-key': this.apikey,
    });
    return this.getDatos = this.http.post<any>(this.urlBase + '/Brands', raw, { headers }).subscribe(data => {
    }, error => {
    });
  }

  getProductsView(data) {
    let headers = new HttpHeaders({
      'api-key': this.apikey,
      'length': data.length,
      'whereone': data.whereone,
    });
    return this.getDatos = this.http.get<any>(this.urlBase + `/ProductsView`, { headers });
  }

  updateUser(messages, uid) {
    let raw = JSON.stringify(messages);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.put<User>(this.urlBase + '/Users/' + uid + '/' + this.apikey + '/1/1', raw, { headers });
  }

  getDashboardStatistics() {
    let headers = new HttpHeaders({
      'api-key': this.apikey,
    });
    return this.getDatos = this.http.get<any>(this.urlBase + `/Dashboard/v1/statistics`, { headers });
  }

  getOrdersStatistics() {
    let headers = new HttpHeaders({
      'api-key': this.apikey,
    });
    return this.getDatos = this.http.get<any>(this.urlBase + `/Dashboard/orders/statistics`, { headers });
  }

  getUsersStatistics() {
    let headers = new HttpHeaders({
      'api-key': this.apikey,
    });
    return this.getDatos = this.http.get<any>(this.urlBase + `/Dashboard/users/statistics`, { headers });
  }

  async saveUser(messages: any) {
    return new Promise((resolve, reject) => {
      let raw = JSON.stringify(messages);
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      this.http.post<any>(this.urlBase + '/Users/' + this.apikey + '/1/0', raw, { headers })
        .subscribe(data => {
          resolve(data)
        }, error => {
          reject(error);
        });
    });
  }

  getClassifications(orderField, orderBy) {
    const headers = new HttpHeaders({
      'api-key': this.apikey,
    });
    return this.getDatos = this.http.get<any>(this.urlBase + `/Classifications/${orderField}/${orderBy}`, { headers });
  }

  updateClassification(message_post, id) {
    return new Promise((resolve, reject) => {
      let raw = JSON.stringify(message_post);
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'api-key': this.apikey,
      });
      return this.getDatos = this.http.put<any>(this.urlBase + `/Classifications/${id}`, raw, { headers }).subscribe(data => {
        resolve(data['_body']);
      }, error => {
        reject(error);
      });
    })
  }

  getClassification(id) {
    let headers = new HttpHeaders({
      'api-key': this.apikey,
    });
    return this.getDatos = this.http.get<any>(this.urlBase + `/Classifications/${id}`, { headers });
  }

  async saveClassification(messages) {
    let raw = JSON.stringify(messages);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'api-key': this.apikey,
    });
    return this.getDatos = this.http.post<any>(this.urlBase + '/Classifications', raw, { headers }).subscribe(data => {
    }, error => {
    });
  }

  async sendPOST_AppNotifications(messages) {
    console.log("http: ", messages);
    let raw = JSON.stringify(messages);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post<AppNotifications>(this.urlBase + '/AppNotifications/' + this.apikey + '', raw, { headers })
      .subscribe(data => {
        console.log(data);
      }, error => {
        console.log(error);
      });
  }

  getOneSlider(uid) {
    let headers = new HttpHeaders({
      'api-key': this.apikey,
    });
    return this.getDatos = this.http.get<any>(this.urlBase + `/Sliders/${uid}`, { headers });
  }

  async updateSlider(message_post, id) {
    return new Promise((resolve, reject) => {
      let raw = JSON.stringify(message_post);
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'api-key': this.apikey,
      });
      return this.getDatos = this.http.put<any>(this.urlBase + `/Sliders/${id}`, raw, { headers }).subscribe(data => {
        resolve(data['_body']);
      }, error => {
        reject(error);
      });
    })
  }

  getContentsWeb() {
    let headers = new HttpHeaders({
      'api-key': this.apikey,
    });
    return this.getDatos = this.http.get<any>(this.urlBase + `/ContentsWeb`, { headers });
  }

  updateContentsWeb(message_post, id) {
    return new Promise((resolve, reject) => {
      let raw = JSON.stringify(message_post);
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'api-key': this.apikey,
      });
      return this.getDatos = this.http.put<any>(this.urlBase + `/ContentsWeb/${id}`, raw, { headers }).subscribe(data => {
        resolve(data['_body']);
      }, error => {
        reject(error);
      });
    })
  }

  getOrderByUid(uid) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'api-key': this.apikey,
    });
    return this.http.get<any>(this.urlBase + '/Orders/' + uid, { headers });
  }

  sendPUT_Orders2(messages, id) {
    return new Promise((resolve, reject) => {
      var raw = JSON.stringify(messages);
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      console.log("http: ", raw);

      return this.getDatos = this.http.put<any>(this.urlBase + '/Orders/' + id + '/' + this.apikey + '/1/1', raw, { headers })
        .subscribe(data => {
          console.log(data);
          resolve(data);
        }, error => {
          console.log(error);
          reject(error);
        });
    });
  }

  sendPUT_StockProducts(messages) {
    return new Promise((resolve, reject) => {
      var raw = JSON.stringify({ items: messages});
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'api-key': this.apikey,
      });
      console.log("http: ", raw);

      return this.getDatos = this.http.put<any>(this.urlBase + '/Stock/v1/proccess/order', raw, { headers })
        .subscribe(data => {
          console.log(data);
          resolve(data);
        }, error => {
          console.log(error);
          reject(error);
        });
    });
  }
}
