import { Injectable } from '@angular/core';
import { combineLatest, Observable, switchMap } from "rxjs";
import { AngularFirestore, AngularFirestoreDocument } from "@angular/fire/firestore";
import { map } from "rxjs/operators";
import { QueryFn } from '@angular/fire/firestore';



@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  constructor(
    private afs: AngularFirestore
  ) { }

  public get(path: string): Promise<AngularFirestoreDocument<{}>> {
    return new Promise(resolve => {
      resolve(this.afs.doc(path));
    });
  }

  public getOnePath<T>(path): Observable<any> {
    return this.afs.doc<T>(`${path}`).valueChanges();
  }

  public getAll<T>(collection: string): Observable<any> {
    return this.afs.collection<T>(collection).valueChanges({ idField: 'id' });
  }

  public getOneWatcher<T>(collection: string, uid: string): Observable<any> {
    return this.afs.doc<T>(`${collection}/${uid}`).valueChanges();
  }

  /* public save<T>(collection: string, document): Promise<any> {
     document.uid = document.uid != null && document.uid != '' && document.uid != undefined ? document.uid : this.afs.createId();
     return this.afs.doc<T>(`${collection}/${document.uid}`).set(document);
   }*/

  createId() {
    return this.afs.createId();
  }

  public save<T>(collection: string, document): Promise<any> {
    document.uid = document.uid != null && document.uid != '' && document.uid != undefined ? document.uid : this.afs.createId();
    console.log('document.uid', document.uid);
    return this.afs.doc<T>(`${collection}/${document.uid}`).set(document, { merge: true });
  }

  public save2<T>(collection: string, document: any): Promise<any> {
    document.user_uid = document.user_uid != null && document.user_uid != '' && document.user_uid != undefined ? document.user_uid : this.afs.createId();
    return this.afs.doc<T>(`${collection}/${document.user_uid}`).set(document);
  }

  public update<T>(collection: string, uid: string, document: any) {
    return this.afs.collection(collection).doc(uid).update(document)
    //return this.afs.doc<T>(`${collection}/${uid}`).update(document);
  }

  public createID() {
    return this.afs.createId();
  }

  public saveSubcollection<T>(collection: string, docID: string, subcollection: string, subdocID: string, document: T): Promise<void> {
    if (!docID || docID == '') {
      docID = this.afs.createId();
    }
    return this.afs.collection(collection).doc(docID).collection(subcollection).doc(subdocID).set(document, { merge: true });
  }



  // public getWhere<T>(collection: string, key: string, value: any): Observable<any> {
  //   return this.afs.collection<T>(collection, ref => ref.where(key, '==', value)).valueChanges();
  // }

  public getWhere<T>(collection: string, key: string, value: any): Observable<any> {
    return this.afs.collection<T>(collection, ref => ref.where(key, '==', value)).snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as T;
          const uid = a.payload.doc.id;
          const id = a.payload.doc.id;
          return { id, uid, ...data };
        });
      })
    );
  }

  public getWhere3<T>(collection: string, key1: string | firebase.firestore.FieldPath, value1: any, key2: string | firebase.firestore.FieldPath, value2: any): Observable<any> {
    return this.afs
      .collection<T>(collection, ref => ref
        .where(key1, '==', value1)
        .where(key2, '!=', value2)
        //.orderBy(key2)
        //.startAt(value2)
        //.endAt(value2+'\uf8ff')
      )
      .valueChanges({ idField: 'id' });
  }

  public getWhereProduct<T>(collection: string, key: string, value: any): Observable<any> {
    return this.afs.collection<T>(collection, ref => ref.where(key, '==', value)).snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as T;
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }

  public getWhere4<T>(collection: string, key1: string | firebase.firestore.FieldPath, value1: any, key2: string | firebase.firestore.FieldPath, value2: any): Observable<any> {

    return this.afs
      .collection<T>(collection, ref => ref
        .where(key1, '==', value1)
        .where(key2, '>', value2)
        // .orderBy('created_at')
        // .startAt(date1)
        // .endAt(date2)
        //.endAt(value3+'\uf8ff')
      )
      .valueChanges({ idField: 'id' });
  }

  public getWhere5<T>(collection: string, date1: any, date2: any, key3: string | firebase.firestore.FieldPath): Observable<any> {

    return this.afs
      .collection<T>(collection, ref => ref
        .orderBy(key3)
        .startAt(date1)
        .endAt(date2)
      )
      .valueChanges({ idField: 'id' });
  }

  public getWhere6<T>(collection: string, key1: string | firebase.firestore.FieldPath, value1: any, key3: string | firebase.firestore.FieldPath, date1: any, date2: any | firebase.firestore.FieldPath): Observable<any> {
    return this.afs
      .collection<T>(collection, ref => ref
        .where(key1, '==', value1)
        .orderBy(key3)
        .startAt(date1)
        .endAt(date2)
      )
      .valueChanges({ idField: 'id' });
  }

  // public getCountWhere<T>(collection: string, key: string, value: any): Observable<number> {
  //   return this.afs.collection<T>(collection, ref => ref.where(key, '==', value)).get().pipe(
  //       map(snapshot => snapshot.size)
  //   );
  // }

  public getCountWhere<T>(collection: string, conditions: { key: string, operator: any, value: any }[]): Observable<number> {
    return this.afs.collection<T>(collection, ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      conditions.forEach(condition => {
        query = query.where(condition.key, condition.operator, condition.value);
      });
      return query;
    }).get().pipe(
      map(snapshot => snapshot.size)
    );
  }

  public getCountWhere2<T>(collection: string, conditions: { key: string, operator: any, value: any }[], date1, date2): Observable<number> {
    return this.afs.collection<T>(collection, ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      conditions.forEach(condition => {
        query = query.where(condition.key, condition.operator, condition.value)
      });
      return query.orderBy('created_at')
        .startAt(date1)
        .endAt(date2);
    }).get().pipe(
      map(snapshot => snapshot.size)
    );
  }

  public getCountWhere3<T>(collection: string, conditions: { key: string, operator: any, value: any }[], date1, date2): Observable<number> {
    return this.afs.collection<T>(collection, ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      conditions.forEach(condition => {
        query = query.where(condition.key, condition.operator, condition.value)
      });
      return query.orderBy('products_stock')
        .startAt(date1)
        .endAt(date2);
    }).get().pipe(
      map(snapshot => snapshot.size)
    );
  }

  public getWhere2<T>(collection: string, conditions: { key: string, operator: any, value: any }[]): Observable<any> {
    const queryFn = (ref: firebase.firestore.CollectionReference | firebase.firestore.Query) => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      conditions.forEach(condition => {
        query = query.where(condition.key, condition.operator, condition.value);
      });
      return query;
    };

    return this.afs.collection<T>(collection, queryFn).snapshotChanges().pipe(
      switchMap(actions => {
        const mappedActions = actions.map(a => {
          const data = a.payload.doc.data() as T;
          const uid = a.payload.doc.id;
          return { uid, ...data };
        });

        const observables = mappedActions.map(item => {
          return this.afs.doc<T>(`${collection}/${item.uid}`).valueChanges().pipe(
            map(data => ({ uid: item.uid, ...data }))
          );
        });

        return combineLatest(observables);
      })
    );
  }

  public getAllSubcollection<T>(collection: string, docID: string, subcollection: string): Observable<any> {
    return this.afs.collection<T>(collection).doc(docID).collection(subcollection).valueChanges({ idField: 'id' });
  }

  public getSubcollectionWhere<T>(parentCollection: string, parentId: string, subcollection: string, key: string, value: any): Observable<any> {
    return this.afs.collection(parentCollection).doc(parentId).collection(subcollection, ref => ref.where(key, '==', value)).snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as T;
          const uid = a.payload.doc.id;
          return { uid, ...data };
        });
      })
    );
  }

  public getDocument<T>(path: string): Observable<T> {
    return this.afs.doc<T>(path).valueChanges();
  }


  public search(collectionName, objectSearch) {
    // console.log(objectSearch);

    /*
      After applying these query you may face this error:
      "ERROR FirebaseError: The query requires an index. You can create it here: URL"
      You will get above error with an URL - Click over that URL - Login in Firebase
      and this will prompt to Create an Index which is required in Firebase
      to apply queries to Database Collection.
    */
    return this.afs.collection(collectionName, ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      //for (const prop in objectSearch) { query = query.where(`${prop}`, '==', `${objectSearch[prop]}`); }
      if (objectSearch.phone) {
        // console.log('objectSearch.phone ' + objectSearch.phone);
        query = query.where('phone', '==', objectSearch.phone);
      }
      if (objectSearch.locations_uid) {
        // console.log('objectSearch.locations_uid ' + objectSearch.locations_uid);
        query = query.where('locations_uid', '==', objectSearch.locations_uid);
      }
      if (objectSearch.commerce_uid && objectSearch.commerce_uid != '' && objectSearch.commerce_uid != undefined) {
        query = query.where('commerce_uid', '==', objectSearch.commerce_uid);
      }
      if (objectSearch.orders_status_uid && objectSearch.orders_status_uid != '' && objectSearch.orders_status_uid != undefined) {
        query = query.where('orders_status_uid', '==', objectSearch.orders_status_uid);
      }
      if (objectSearch.manager_user_uid && objectSearch.manager_user_uid != '' && objectSearch.manager_user_uid != undefined) {
        query = query.where('manager_user_uid', '==', objectSearch.manager_user_uid);
      }
      if (objectSearch.user_uid && objectSearch.user_uid != '' && objectSearch.user_uid != undefined) {
        query = query.where('user_uid', '==', objectSearch.user_uid);
      }
      if (objectSearch.category_uid) {
        query = query.where('category_uid', '==', objectSearch.category_uid);
      }
      if (objectSearch.joinDate) {
        objectSearch.joinDateTimestamp = new Date(objectSearch.joinDate);
        query = query.where('joinDateTimestamp', '==', objectSearch.joinDateTimestamp);
      }
      if (objectSearch.name) {
        query = query.where('name', '>=', objectSearch.name)
        query = query.where('name', '<=', objectSearch.name + '\uf8ff')
      }
      if (objectSearch.limit) {
        query = query.limit(objectSearch.limit)
      }
      if (objectSearch.orderByName) {
        // console.log('entro aqui')
        query = query.orderBy(objectSearch.orderByName, objectSearch.orderByDir);
      }

      return query;
    }).snapshotChanges();
  }
}
